import React, { useState, useEffect } from "react";
import { Pagination as BootstrapPagination } from "react-bootstrap";

type PaginationProps = {
  totalItems: number;
  pageSize: number;
  currentPage?: number;
  onPageChange(page: number): void;
  size?: "sm" | "lg";
};

export const Pagination = ({
  totalItems,
  pageSize,
  currentPage,
  onPageChange,
  size,
}: PaginationProps) => {
  const [totalPage, setTotalPage] = useState(0);
  const [_currentPage, setCurrentPage] = useState(currentPage || 1);

  useEffect(() => {
    let totalPage = Math.ceil(totalItems / pageSize);
    setTotalPage(totalPage);
    if (currentPage) {
      setCurrentPage(currentPage);
    }
  }, [pageSize, totalItems, currentPage]);

  const setPage = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPage) {
      setCurrentPage(pageNumber);
      onPageChange(pageNumber);
    }
  };

  const firstPage = () => {
    setCurrentPage(1);
    onPageChange(_currentPage);
  };

  const prevPage = () => {
    let prevPage = _currentPage > 1 ? _currentPage - 1 : 1;
    setCurrentPage(prevPage);
    onPageChange(prevPage);
  };

  const lastPage = () => {
    setCurrentPage(totalPage);
    onPageChange(totalPage);
  };

  const nextPage = () => {
    let nextPageValue =
      _currentPage + 1 < totalPage ? _currentPage + 1 : totalPage;
    setCurrentPage(nextPageValue);
    onPageChange(nextPageValue);
  };

  const renderPaginationItems = () => {
    let pagesDisplayed = [];
    let pagesRendered: Array<any> = [];
    let lastPageRendered;

    for (let page = 1; page <= totalPage; page++) {
      if (
        page < 4 ||
        page > totalPage - 3 || // 3 first pages and 3 last pages
        (_currentPage >= page - 1 && _currentPage <= page + 1)
      ) {
        // 3 'XXX' pages between start and end 123...XXX...789
        if (pagesDisplayed.indexOf(page) === -1) {
          if (lastPageRendered && page - 1 !== lastPageRendered) {
            pagesRendered.push(
              <BootstrapPagination.Ellipsis key={"ellipsis" + page} />
            );
          }
          pagesDisplayed.push(page);
          pagesRendered.push(
            <BootstrapPagination.Item
              key={page}
              onClick={() => {
                setPage(page);
              }}
              {...(page === _currentPage ? { active: true } : {})}
            >
              {page}
            </BootstrapPagination.Item>
          );
          lastPageRendered = page;
        }
      }
    }

    return pagesRendered;
  };

  const renderPagination = () => {
    let displayFirstPage,
      displayLastPage,
      displayPrevPage,
      displayNextPage = false;
    if (totalPage > 4) {
      displayFirstPage = true;
      displayLastPage = true;
      displayPrevPage = true;
      displayNextPage = true;
    }

    return (
      <BootstrapPagination size={size}>
        {totalPage > 1 && (
          <React.Fragment>
            {displayFirstPage && (
              <BootstrapPagination.First
                onClick={() => {
                  firstPage();
                }}
              />
            )}
            {displayPrevPage && (
              <BootstrapPagination.Prev
                onClick={() => {
                  prevPage();
                }}
              />
            )}
            {renderPaginationItems().map((item) => item)}
            {displayNextPage && (
              <BootstrapPagination.Next
                onClick={() => {
                  nextPage();
                }}
              />
            )}
            {displayLastPage && (
              <BootstrapPagination.Last
                onClick={() => {
                  lastPage();
                }}
              />
            )}
          </React.Fragment>
        )}
      </BootstrapPagination>
    );
  };

  return <>{renderPagination()}</>;
};
